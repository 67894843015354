import "./App.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./Home";
import "animate.css/animate.min.css";
import Personal from "./Personal";
import Product from "./Product";
import Company from "./Company";
import TermsOfUse from "./TermsOfUse";
import Blog from "./blog";
import Faq from "./Faq";
import Page404 from "./404";
import Privacy from "./Privacy";
import AllBlogs from "./allBlogs";
import BlogPage from "./blogPage";
import Bussiness from "./Bussiness";

import SuccessPage from "./PaymentLnk/SuccessPage";
import Pay from "./PaymentLnk/Pay";
import Checkout from "./PaymentLnk/Checkout";
import InvoicePay from "./Invoice/Pay";


function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Bussiness />} />
        <Route path="/personal" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/company" element={<Company />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/pay/:slug" element={<Pay />} />
        <Route path="/pay-invoice/:slug" element={<InvoicePay />} />
        {/* <Route path="/business" element={<Bussiness />} /> */}
        <Route path="/allblog" element={<AllBlogs />} />
        <Route path="/allblog/:slug" element={<BlogPage />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="*" element={<Page404 />} />
        <Route path="privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;
