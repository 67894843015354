import React from 'react'

const Partners = () => {
  return (
    <section className="mt-[84px] px-[30px] max-w-[996px] mx-auto mb-[60px] md:mb-[100px] lg:mb-[120px]">
    <p className="text-center text-[#878787] mb-[40px]">
      Some of Our Partners
    </p>
    {/* <ScrollAnimation animateIn="animate__fadeInLeft" duration={1}> */}
    <ul className="flex justify-evenly  items-center flex-wrap gap-4">
      <li>
        <img
          src="./image/aws.png"
          alt="aws logo"
          className="h-[20px]  md:h-[34px]"
        />
      </li>
      <li>
        <img
          src="./image/techimpact.png"
          alt="techimpact logo"
          className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img src="./image/microsoft.png" alt="microsoft logo"
        
        className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img src="./image/creditRegistry.png" alt="creditRegistry logo"
        
        className="h-[20px] md:h-[34px] w-[80px]"
        />
      </li>
      <li>
        <img src="./image/visa.webp" alt="visa logo"
        
        className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img src="./image/stripe.png" alt="stripe logo"
        
        className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img src="./image/fintechngr.png" alt="fintechngr logo"
        
        className="h-[20px] md:h-[34px] w-[90px]"
        />
      </li>
   
      <li>
        <img src="./image/fateFoundation.webp" alt="fateFoundation logo"
        
        className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img src="./image/lgInovative.png" alt="lgInovative logo"
        
        className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img src="./image/ocn.png" alt="ocn logo"
        
        className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img
          src="./image/vfd.png"
          alt="vdf logo"
          className="h-[20px] md:h-[34px]"
        />
      </li>
      <li>
        <img
          src="./image/providus.png"
          alt="providus logo"
          className="h-[20px] md:h-[34px]"
        />
      </li>
    </ul>
    {/* </ScrollAnimation> */}
  </section>
)
}

export default Partners